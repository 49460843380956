import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const fbConfig = {
    apiKey: 'AIzaSyCM38G6s4q2REoB-T7jv5J9jdOtoWAAJH4',
    authDomain: 'enfoco-red.firebaseapp.com',
    projectId: 'enfoco-red',
    storageBucket: 'enfoco-red.appspot.com',
    messagingSenderId: '460053601764',
    appId: '1:460053601764:web:6ca2ab2db3f7b200467bf1',
    measurementId: 'G-76PBVW20ZJ',
};

const fb = firebase.initializeApp(fbConfig);

export { fb, fbConfig };
